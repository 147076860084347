export const searchAdapter = (data) => {
  return data.map((searchResult) => {
    const { page } = searchResult;

    return {
      url: searchResult.url,
      title: searchResult.title,
      text: searchResult.text,

      blockId: page?.block_id,
      entityId: page?.id || searchResult.type,
    };
  });
};
