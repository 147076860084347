<script setup>
import merge from 'lodash/merge';

const props = defineProps({
  sectionsAdditionalData: {
    type: Object,
    default: undefined,
  },
});

const pagesStore = usePagesStore();
const route = useRoute();

const { data: pageData, error: pageError } = await useAsyncData(
  route.path,
  () => pagesStore.fetchPage(route.path),
);

if (pageError.value) {
  const { statusCode, statusMessage } = pageError.value;
  throw createError({ statusCode, statusMessage, fatal: true });
}

const pageMeta = computed(() => pageData.value?.meta);

useSeo({
  title: () => pageMeta.value?.title,
  description: () => pageMeta.value?.description,
  image: () => pageMeta.value?.sharingImage,
});

const pageSections = computed(() => {
  if (!pageData.value) return;

  if (props.sectionsAdditionalData) {
    Object.entries(props.sectionsAdditionalData).forEach(
      ([sectionIndex, sectionData]) => {
        const { sections } = pageData.value;

        merge(sections[sectionIndex]?.data, sectionData);
      },
    );
  }

  return pageData.value.sections;
});
</script>

<template>
  <BundlesConstructor v-if="pageData" :sections="pageSections" />
</template>
