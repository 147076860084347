import { pageAdapter } from '@/adapters/page';
import { searchAdapter } from '@/adapters/search';

export const usePagesStore = defineStore({
  id: 'pages',
  actions: {
    fetchPage(path) {
      return useRequest(`/pages${path}`).then(({ data }) => pageAdapter(data));
    },
    search(text) {
      return useRequest('/search', { query: { text } }).then(({ data }) =>
        searchAdapter(data),
      );
    },
  },
});
